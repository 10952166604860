import React from "react"
import { Link } from "gatsby"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Video from "../video/web-header-video-landscape.mp4"
import Heart from "../images/heart_logo_cropped.png"
import Logo from "../images/logo_justText.png"
import appStore from "../images/badge-apple.png"
import playStore from "../images/badge-google.png"
import heroBG from "../images/hero_bg.jpg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100% !important",
    margin: "0",
    padding: "0",
    height: "100vh !important",
    overflow: "hidden",
    textAlign: "center",
  },
  container: {
    width: "100% !important",
    margin: "0",
    padding: "0",
    height: "100vh !important",
    overflow: "hidden",
    backgroundColor: "rgba(52,16,52,0.6)",
    textAlign: "center",
    zIndex: "100",
  },
  image_overlay: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100vh !important",
    opacity: "0.6",
    zIndex: "-1",
    overflow: "hidden",
    "& img": {
      minWidth: "100% !important",
      minHeight: "100vh !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  video_overlay: {
    position: "absolute",
    overflow: "hidden",
    top: 0,
    zIndex: "-10",
    objectFit: "cover",
    height: "100vh",
    "& video": {
      objectFit: "cover",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: "0",
      left: "0",
    },
  },
  heroLogo: {
    textAlign: "center",
    placeContent: "center",
    margin: "0 !important",
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
    padding: "0 0 10px 0",
  },
  heroLogoHeart: {
    textAlign: "center",
    placeContent: "center",
    margin: "0 !important",
    width: "60px",
  },
  heroHeader: {
    color: "#fff",
    fontSize: "2.5rem !important",
    fontWeight: "700",
    padding: "0 0 20px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem !important",
    },
  },
  heroText: {
    color: "#fff",
    fontSize: "1.5rem !important",
    fontWeight: "300",
    padding: "0 0 20px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heroDownButton: {
    position: "absolute",
    display: "grid",
    width: "100%",
    bottom: "0",
    textAlign: "center",
    placeContent: "center",
    "& svg": {
      color: "#fff",
      fontSize: "5rem",
    },
  },
  appBadges: {
    cursor: "pointer",
    "& :hover": {
      boxShadow: "0 0px 5px 5px rgba(0,0,0,1)",
    },
  },
}))

const Hero = ({ heading, subHeading, hideButtons, scrollLink }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.video_overlay}>
        <video width="100%" loop muted autoPlay playsInline>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <div className={classes.image_overlay}>
        <img src={heroBG} alt="background" />
      </div>
      <Grid
        container
        direction={"column"}
        justify="center"
        className={classes.container}
      >
        <Grid item>
          <img
            className={classes.heroLogoHeart}
            src={Heart}
            alt="rajaa weds rani"
          />
          <br />
          <img className={classes.heroLogo} src={Logo} alt="rajaa weds rani" />
        </Grid>
        <div>
          <Typography component="h1" className={classes.heroHeader}>
            {heading}
          </Typography>
        </div>
        <div>
          <Typography component="p" className={classes.heroText}>
            {subHeading}
          </Typography>
        </div>
        {!hideButtons && (
          <Grid
            item
            container
            alignContent="center"
            justify="center"
            spacing={3}
          >
            <Grid item className={classes.appBadges}>
              <img src={appStore} alt="App Store" />
            </Grid>
            <Grid item className={classes.appBadges}>
              <img src={playStore} alt="Play Store" />
            </Grid>
          </Grid>
        )}
      </Grid>
      <div className={classes.heroDownButton}>
        <Link to={scrollLink}>
          <ExpandMoreIcon />
        </Link>
      </div>
    </div>
  )
}

export default Hero
