import React from 'react'
import { useInView } from "react-intersection-observer"

const SlideUp = ({ threshold = 0.0, children }) => {
  const [ref, inView] = useInView({ threshold })

  return (
    <div
      style={{
        transition: "opacity 1000ms, transform 1500ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 20}px)`,
        width: 'inherit',
        display: 'inherit',
        height: 'inherit',
        flexDirection:'inherit'
      }}
      ref={ref}
    >
    {children}
    </div>
  )
}

export default SlideUp