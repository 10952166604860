import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import 'animate.css'
//import bgImage from '../images/bg.png'

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'grid',  
    Width: "100%",
    // minHeight: "80vh",
    backgroundColor:'#fff', 
    textAlign: 'center',
    placeContent: 'center',
    paddingTop: "60px",
    paddingBottom:"60px",
    [theme.breakpoints.down('sm')]:{
      paddingTop: "20px",
      paddingBottom:"20px"
    }
  },
  container:{
    maxWidth: '1200px', 
    [theme.breakpoints.down('md')]:{
      maxWidth: '100%',
      padding:"0 20px"
    }
  }
}))

const Section = ({ id, children, colorBG, img }) => {
  const classes = useStyle()
  return (
    <section id={id} className={clsx(classes.root)} style={{backgroundColor:`${colorBG}`, backgroundImage:`url(${img})`}}>
      <div className={classes.container}>
        {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string,
  colorBG: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  id: ``,
  colorBG: `#fff`,

}

export default Section
