import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SlideUp from "../components/animations/slideUp"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import Section from "../components/Section"
import Hero from "../components/hero"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  heading: {
    paddingBottom: "25px",
    textAlign: "center",
  },
  heroLogoHeart: {
    textAlign: "center",
    margin: "0 auto",
    width: "80px",
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  title: {
    textAlign: "left",
    paddingBottom: "20px",
    paddingTop: "25px",
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    paddingBottom: "10px",
  },
  listStyle: {
    width: "fit-content",
    paddingBottom: "30px",
    textAlign: "left",
  },
}))

const PrivacyPolicy = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Privacy Policy - Muslim Matrimony Site" />
      <Hero
        heading="Privacy Policy"
        subHeading=""
        hideButtons={true}
        scrollLink="#privacy-policy"
      />

      <Section id="privacy-policy">
        <Grid
          container
          justify="center"
          direction="column"
          className={classes.root}
        >
          <Grid item className={`${classes.section}`}>
            <SlideUp>
              <Typography
                component="h2"
                className={`${classes.title} heading purple`}
              >
                Introduction
              </Typography>

              <Typography component="p" className={classes.paragraph}>
                Welcome to RajaaRani, a Muslim matrimony app dedicated to
                helping individuals find their life partners. Your privacy is of
                utmost importance to us. This Privacy Policy outlines how we
                collect, use, disclose, and protect your personal information
                when you use our services.
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Information We Collect
              </Typography>
              <Typography className={classes.paragraph}>
                We collect the following types of information:
              </Typography>
              <Typography className={classes.paragraph}>
                <strong>1. Personal Information:</strong>
                <ul className={classes.listStyle}>
                  <li>Name</li>
                  <li>Age/Date of Birth</li>
                  <li>Gender</li>
                  <li>Contact Information (email address, phone number)</li>
                  <li>Location (city, state, country)</li>
                  <li>
                    Religious Details (sect, level of religiousness, etc.)
                  </li>
                  <li>Marital Status</li>
                  <li>Photographs</li>
                </ul>
              </Typography>
              <Typography className={classes.paragraph}>
                <strong>2. Profile Information:</strong>
                <ul className={classes.listStyle}>
                  <li>Bio/Description</li>
                  <li>Education</li>
                  <li>Occupation</li>
                  <li>Preferences for a partner</li>
                </ul>
              </Typography>
              <Typography className={classes.paragraph}>
                <strong>3. Usage Information:</strong>
                <ul className={classes.listStyle}>
                  <li>Browsing history within the app</li>
                  <li>Interaction with other users</li>
                  <li>Communication records within the app</li>
                </ul>
              </Typography>
              <Typography className={classes.paragraph}>
                <strong>4. Device Information:</strong>
                <ul className={classes.listStyle}>
                  <li>Device type</li>
                  <li>Operating system</li>
                  <li>IP address</li>
                  <li>Mobile network information</li>
                </ul>
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                How We Use Your Information
              </Typography>
              <Typography className={classes.paragraph}>
                We use the collected information for the following purposes:
                <ul className={classes.listStyle}>
                  <li>To create and manage your account</li>
                  <li>
                    To facilitate matchmaking and connect you with potential
                    partners
                  </li>
                  <li>To improve our services and user experience</li>
                  <li>
                    To communicate with you regarding your account or our
                    services
                  </li>
                  <li>To ensure the safety and security of our users</li>
                  <li>To comply with legal obligations</li>
                </ul>
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Sharing Your Information
              </Typography>
              <Typography className={classes.paragraph}>
                We may share your information with:
                <ul className={classes.listStyle}>
                  <li>
                    <strong>Other Users:</strong> To facilitate connections and
                    communications.
                  </li>
                  <li>
                    <strong>Service Providers:</strong> Who assist us in
                    operating our app and providing services.
                  </li>
                  <li>
                    <strong>Legal Authorities:</strong> If required by law or in
                    response to legal processes.
                  </li>
                </ul>
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Security of Your Information
              </Typography>
              <Typography className={classes.paragraph}>
                We take reasonable measures to protect your information from
                unauthorized access, use, or disclosure. However, please note
                that no internet-based service can be completely secure.
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Your Rights
              </Typography>
              <Typography className={classes.paragraph}>
                You have the right to:
                <ul className={classes.listStyle}>
                  <li>Access and update your personal information</li>
                  <li>Delete your account and personal information</li>
                  <li>Object to or restrict certain types of processing</li>
                  <li>
                    Withdraw consent where we rely on it for processing your
                    information
                  </li>
                </ul>
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Data Retention
              </Typography>
              <Typography className={classes.paragraph}>
                We retain your personal information for as long as your account
                is active or as needed to provide you with our services. We may
                also retain your information to comply with legal obligations,
                resolve disputes, and enforce our agreements.
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Changes to This Privacy Policy
              </Typography>
              <Typography className={classes.paragraph}>
                We may update this Privacy Policy from time to time. We will
                notify you of any significant changes by posting the new Privacy
                Policy on our website and app, and we will indicate the date of
                the latest revision.
              </Typography>

              <Typography
                component="h2"
                variant="h3"
                className={`${classes.title} heading purple`}
              >
                Contact Us
              </Typography>
              <Typography className={classes.paragraph}>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at:
                <br />
                RajaaRani
                <br />
                Email:{" "}
                <b>
                  <a href="mailto:support@rajaarani.com">
                    support@rajaarani.com
                  </a>
                </b>
                <br />
                Thank you for trusting RajaaRani with your personal information.
                We are committed to providing you with a safe and secure
                matchmaking experience.
              </Typography>

              <Typography className={classes.paragraph}>
                This Privacy Policy is subject to the terms and conditions of
                RajaaRani. Please read our Terms of Service for more
                information.
              </Typography>

              <Typography className={classes.paragraph}>
                <br />
                <br />
                <br />
                <br />
                <b>RajaaRani Team</b>
                <br />
                London, UK
                <br />
                rajaarani.com
              </Typography>
            </SlideUp>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicy
